@tailwind base;
@tailwind components;
@tailwind utilities;

/* 개인정보 처리방침 스크롤 바 */
@layer utilities {
  .scrollbar-always {
    overflow-y: scroll; /* Always show scrollbar */
  }

  .scrollbar-always::-webkit-scrollbar {
    width: 8px;
  }

  .scrollbar-always::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }

  .scrollbar-always::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }
}
